<!-- [팝업] 컨텐츠 검색 -->
<template>
  <b-modal
    v-model="showContentSelect"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="컨텐츠 선택"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="mt-25"
    >
      <b-col
        cols="12"
        md="10"
        class="d-flex align-items-center justify-content-center"
      >
        <b-form-input
          v-model="query.filterStr"
          class="el-def"
          placeholder="검색어를 입력하세요."
          @keyup.enter="refetchData()"
        />
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="refetchData()"
        >
          조회
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-table
        ref="refContentTable"
        primary-key="id"
        :items="fetchContents"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedContent"
        @row-dblclicked="onRowDblClickedContent"
      >
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(startDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
      </b-table>
    </b-row>
  </b-modal>
</template>

<script>
import axios from '@axios'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
  },

  props: {
    showContentSelect: {
      type: Boolean,
      required: true,
    },
    contentType: {
      type: Number,
      default: null,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
    })

    watch(() => props.contentType, newVal => {
      query.value.contentType = newVal
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Query Data
    const query = ref({
      contentType: 7, // 이벤트 컨텐츠
      filterStr: null,
    })
    // Query Data End.

    const selectedContent = ref(null)

    // Main Table Config
    const refContentTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: 'id', label: 'Id', sortable: true,
      },
      {
        key: 'title', label: '제목', sortable: true, thStyle: { width: '70%' },
      },
      {
        key: 'startDate', label: '게시 시작일', sortable: true,
      },
    ]
    const refetchData = () => {
      refContentTable.value.refresh()
    }
    const onRowSelectedContent = items => {
      const item = items[0]
      if (item) {
        selectedContent.value = item
      }
    }
    const onRowDblClickedContent = item => {
      if (item) {
        selectedContent.value = item
      }
      submit()
    }
    // Main Table Config End.

    const fetchContents = (ctx, callback) => {
      if (!query.value.contentType) {
        pushToast('warning', '콘텐츠 유형이 선택되지 않았습니다.')
        return
      }
      axios.post('/fa/copy-comment-content/search', {
        search: {
          contentType: query.value.contentType,
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '데이터 조회에 실패하였습니다.'))
        })
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      if (selectedContent.value === null) {
        pushToast('warning', '컨텐츠를 선택하세요')
        return
      }

      emit('close', selectedContent.value)
    }

    return {
      query,
      close,
      submit,
      refContentTable,
      sortBy,
      isSortDesc,
      totalRecords,
      tableColumns,
      refetchData,
      onRowSelectedContent,
      onRowDblClickedContent,
      fetchContents,
    }
  },
}
</script>
